.tabContainer {
  margin-top: 8.5em;
  // max-width: 60%;
  .tabList {
    .tab[aria-selected="true"] {
      box-shadow: #61dafb 0 0px 20px;
      color: white;
    }
  }
  .tabPanel {
    margin-top: 1em;
  }

  .search {
    display: inline-flex;
    .MuiInputBase-input {
      color: lightgrey;
    }
    .searchIcon {
      margin-top: 0.5em;
    }
  }
}

.InnerMenu {
  position: fixed;
  top: 5em;
  background-color: #282c34;
  width: 100%;
  height: 3em;
  z-index: 2;
  padding-top: 0.5em;
}

.metaLeagueTitle {
  text-align: left;
}

.more {
  text-align: right;
  color: #61dafb;
  float: right;
}
