.home {
  margin-top: 7em;

  h1 {
    text-align: left;
    margin-top: 1em;
  }
  .tabContainer:first-of-type {
    margin-top: 6em !important;
  }
  .tabContainer {
    margin-top: 3em !important;
  }
  .homeCard {
    text-align: justify;
    color: lightgrey;
    padding: 1em;
  }
}
