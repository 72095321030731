.pokemonCard {
  // overflow: hidden;
  // margin: 0.5em;
  // padding: 1em;
  // height: 18em;
  width: 274px;
  // border-radius: 50%;
  // border-color: #61dafb;
  // border-style: solid;
  // color: #fff;
  // text-align: center;
  // box-shadow: #61dafb 0 0px 20px;

  .CardContent {
    display: flex !important;
    padding: 0.5em !important;
  }

  .cardTypes {
    border: solid 0.5px;
    font-size: smaller;
  }

  h1 {
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0em;
    width: -webkit-fill-available;
  }
  h2 {
    font-size: 1.2em;
    margin-top: 0;
  }
  h3 {
    font-size: 1.1em;
  }
  img {
    width: 96px;
    height: 96px;
  }
  .ShadowIcon {
    position: absolute;
    height: 30px;
    width: 25px;
    top: 4em;
    left: 0em;
  }
  .CardImage {
    position: relative;
  }
  .cardText {
    width: -webkit-fill-available;
  }
  .cardMoves {
    width: 140%;
    text-align: center;
    color: lightgrey;
    display: inline;

    .CardMove {
      font-size: 0.75rem !important;
    }
    .FirstMove {
      padding-top: 0.4em;
    }
  }
  .CardBottom {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr;
    color: lightgrey;
    font-size: smaller;
    .rank {
      color: #61dafb;
      text-align: left;
    }
    .types {
      text-align: center;
    }
    .score {
      text-align: right;
    }
  }
  .error {
    color: #ff9f9f;
  }
}
