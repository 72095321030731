.PokemonPage {
  padding-top: 6.5em;

  .pokemonCard {
    width: 20em !important;
  }

  .pokemonPageBody {
    display: inline-block;
  }
}
