.App {
}

.AppHeader,
.AppBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-family: "IBM Plex Mono,monospace";
  font-feature-settings: "liga" 0;
  line-height: 1.7;
}

.breadcrumbs {
  // margin-top: 4em;
  position: fixed;
  top: 3em;
  left: 1em;
  background-color: #282c34;
  width: 100%;
  height: 3em;
  z-index: 2;
  padding-top: 0.5em;

  .MuiTypography-colorTextSecondary {
    color: #61dafb;
  }
}

.AppBody {
  padding-bottom: 1em;
  background-color: #282c34;
}

.AppHeader {
  position: fixed;
  width: 100%;
  z-index: 2;
  background-color: #61dafb;
  color: #282c34;
  height: 3em;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.AppHeader h1 {
  color: #282c34;
  margin: 0em;
}

.AppLogo {
  float: left;
}

.AppLink,
h1,
h2,
h3 {
  color: #61dafb;
  a {
    color: #61dafb;
  }
}

h2 {
  font-size: 1.2em;
  margin-top: 0;
}

h3 {
  font-size: 1.1em;
}

.AppFooter {
  color: white;
  height: fit-content;
  background-color: black;
  box-shadow: black 0 0px 10px;
  text-align: left;
  font-size: smaller;

  a {
    padding-left: 0.25em;
  }
}

.FooterBottom {
  text-align: center;
  height: 2em;
  width: 100%;
}

.FooterCredits {
  padding-left: 1em;
  padding-right: 1em;
}

.pokemonCardList {
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  a.pokemonCardLink:link {
    text-decoration: none !important;
  }
}

@media (max-width: 800px) {
  .AppLogo h1 {
    margin-left: 1em;
  }
  .homeCard {
    max-width: 15.15em;
  }
}

@media (min-width: 800px) {
  .pokemonCardList {
    grid-template-columns: repeat(2, 1fr);
  }
  .homeCard {
    max-width: 33.5em;
  }
}

@media (min-width: 1100px) {
  .pokemonCardList {
    grid-template-columns: repeat(3, 1fr);
  }
  .homeCard {
    max-width: 51.5em;
  }
}

@media (min-width: 1500px) {
  .pokemonCardList {
    grid-template-columns: repeat(4, 1fr);
  }
  .homeCard {
    max-width: 70em;
  }
}

.BadgeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  color: white !important;
  display: inline-block;
  padding: 7px 10px;
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  background: #282c34;
  border: solid 0.5px #61dafb;
}

.CardBody {
  display: grid;
  text-align: left;
  grid-template-columns: repeat(2, 1fr);
}

.MuiPaper-root {
  background-color: #343d4e !important;
}

.languageButtonContainer {
  display: flex;
}

.languageButton {
  margin-top: 0.3em;
  background-color: #61dafb;
  font: inherit;
  border: 0.5px solid #282c34;
  cursor: pointer;
  border-radius: 10px;
  width: 7.8em;
  font-size: smaller;
  color: #282c34;
}

.ButtonDisabled {
  background-color: rgba(75, 75, 75, 0.87);
}

.selectedButton {
  box-shadow: #61dafb 0 0px 20px;
  color: white;
}

.MuiSvgIcon-colorPrimary,
.MuiTypography-colorPrimary {
  color: #61dafb !important;
}

.PaddingRight {
  padding-right: 0.25em;
}

.TextSelect {
  color: #61dafb !important;
  .MuiSelect-icon {
    color: #61dafb !important;
  }
}
